.custom-scrollbar::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #939393; /* Set the color of the thumb */
    border-radius: 4px; /* Set the border radius of the thumb */
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #f1f5f9; /* Set the color of the track */
  }
  